<template>
  <div class="bg-white">
    <TsRow append-class="m-0 p-4">
      <TsColumn append-class="p-0 is-0 ms-1">
        <TsIcon name="humbleicons:cart" size="27" />
      </TsColumn>
      <TsColumn append-class="p-0 is-11 lg:flex lg:gap-2 lg:items-center">
        <TsTypography append-class="mb-0" as="h4">
          Items added to trolley
        </TsTypography>
        <TsTag
          variant="info"
          append-class="outline-0 rounded-lg py-1"
          size="sm"
          >
          {{ enableNextDayCollection ? "Next Day Collection" : "Collection" }}
        </TsTag>
      </TsColumn>
      <TsColumn append-class="p-0 is-1">
        <TsIcon
          name="bitcoin-icons:cross-filled"
          @click="handleModalClose"
          class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
        />
      </TsColumn>
    </TsRow>

    <TsDivider append-class="w-full p-0 m-0" />

    <div class="p-5" v-if="props.loading">
      <TsBranchTransition v-for="n in 3" :key="n" />
    </div>

    <div v-else class="max-h-[70vh] overflow-y-auto">
      <div class="px-4 mt-3">
        <template v-if="trolleyStore.previewed_product_v2?.variations?.length">
          <TsTypography append-class="mb-3" as="h4">
            Select Product Variants ({{
              trolleyStore.previewed_product_v2.variations.length
            }})
          </TsTypography>
          <TsButton
            :label="trolleyStore?.previewed_product_v2?.pack_size"
            @click="handleCollectionVariantsClick"
            variant="secondary"
            icon="pepicons-pop:angle-down"
            iconPosition="right"
            append-class="mb-4 mt-0 bg-white justify-between w-full"
            iconClass="text-natural-silver-grey"
            outlined
          />
        </template>

        <TsMedia>
          <TsMediaStart>
            <TsQuantityCounter
              v-if="trolleyStore.trolley_line_items"
              @increment="updateTrolleyItemQuantity"
              @decrement="updateTrolleyItemQuantity"
              @change="updateTrolleyItemQuantity"
              v-model="lineItem.quantity"
            />
            <TsTag
              v-if="enableOutOfStock"
              label="Out of stock"
              variant="danger"
              rounded
              size="sm"
              append-class="m-0 mt-2 py-0.5 px-5"
            />
            <TsTag
              v-else
              variant="info"
              rounded
              size="sm"
              append-class="m-0 mt-2 py-0.5 px-5"
              :label="availableStockForCollection + ' available'"
            />
          </TsMediaStart>
          <TsMediaContent append-class="ms-4">
            <TsRow
              v-if="
                trolleyStore.previewed_product_v2?.prices?.was_price_raw ??
                0 > 0
              "
              append-class="m-0"
            >
              <TsTypography
                size="sm"
                append-class="line-through leading-4 m-0 text-natural-grey"
              >
                {{
                  trolleyStore.previewed_product_v2?.prices?.was_price_formatted
                }}
              </TsTypography>
              <TsTypography
                v-if="
                  trolleyStore.previewed_product_v2?.prices?.raw
                    .discount_percentage
                "
                size="sm"
                weight="bold"
                append-class="text-success leading-tight"
              >
                ({{
                  trolleyStore.previewed_product_v2?.prices?.formatted
                    .discount_percentage
                }}
                OFF)
              </TsTypography>
            </TsRow>
            <TsRow append-class="m-0 items-center gap-1">
              <TsTypography
                variant="h2"
                weight="bold"
                append-class="text-idle-black mb-0 leading-6"
              >
                {{
                  rootStore.isIncVat
                    ? trolleyStore.previewed_product_v2?.prices?.formatted.gross
                    : trolleyStore.previewed_product_v2?.prices?.formatted.net
                }}
              </TsTypography>
              <TsTypography append-class="m-0 leading-4 text-natural-silver-grey" size="xs">
                {{
                  trolleyStore.previewed_product_v2?.prices?.formatted.per_unit
                }}
              </TsTypography>
            </TsRow>
            <TsTypography variant="xs" append-class="leading-4 text-natural-grey">
              {{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
              {{
                rootStore.isIncVat
                  ? trolleyStore.previewed_product_v2?.prices?.formatted.net
                  : trolleyStore.previewed_product_v2?.prices?.formatted.gross
              }}
            </TsTypography>
          </TsMediaContent>
        </TsMedia>

        <TsRow append-class="m-0">
          <TsDivider append-class="w-full mb-2" />
        </TsRow>

        <TsRow
          v-if="enableOutOfStock || enableNextDayCollection"
          is-multiline
          append-class="m-0 mb-3"
        >
          <TsColumn append-class="is-1 p-0 mt-1">
            <NuxtImg
              src="./images/store-location-icon.png"
              width="30"
              class="shrink-0"
            />
          </TsColumn>
          <TsColumn append-class="is-11 p-0">
            <ClientOnly>
              <TsTypography
                v-if="branchStore.lastSavedBranch"
                size="base"
                weight="semibold"
                append-class="text-secondary ms-4 md:ms-0"
              >
                Insufficient stock at
                {{ branchStore.lastSavedBranch?.name }}
              </TsTypography>
            </ClientOnly>
            <TsTypography
              size="sm"
              append-class="text-idle-black ms-4 md:ms-0"
            >
              Need the Product now?
            </TsTypography>
            <TsRow append-class="m-0 justify-between ms-4 md:ms-0">
              <TsButton
                data-testid="collection-modal-find-stock-nearby-button"
                size="sm"
                append-class="text-primary p-0 underline border-none hover:bg-white bg-white hover:text-primary"
                @click="emit('findStock', lineItem.quantity)"
                >Find Stock Nearby
              </TsButton>
              <TsButton
                size="sm"
                @click="emit('setBranch')"
                append-class="text-primary p-0 underline border-none hover:bg-white bg-white hover:text-primary"
              >
                Change branch
              </TsButton>
            </TsRow>
          </TsColumn>
        </TsRow>

        <TsRow v-else is-multiline append-class="m-0 mb-3">
          <ClientOnly>
            <TsColumn append-class="p-0 is-1 mt-1.5">
              <NuxtImg
                src="./images/store-location-icon.png"
                width="30"
                class="shrink-0"
              />
            </TsColumn>
            <TsColumn append-class="p-0 is-11 flex justify-between items-center mt-2">
              <TsTypography
                v-if="branchStore.lastSavedBranch"
                size="base"
                weight="semibold"
                append-class="text-idle-black mb-1 ms-2 md:ms-0"
              >
                Pick at
                {{ branchStore.lastSavedBranch.name }}
                in 15 Mins
              </TsTypography>
              <TsButton
                size="sm"
                @click="emit('setBranch')"
                append-class="text-primary p-0 underline border-none hover:bg-white bg-white hover:text-primary mb-1"
              >
                Change branch
              </TsButton>
            </TsColumn>
          </ClientOnly>
        </TsRow>

      </div>

      <div class="bg-natural-soft-white">
        <TsColumn append-class="pb-1">
          <TsTypography
            weight="semibold"
            append-class="text-idle-black pl-3 leading-5"
          >
            You may also like
          </TsTypography>
        </TsColumn>
        <TsRow
          append-class="m-0 px-3 pb-1 flex overflow-x-auto whitespace-nowrap no-scrollbar"
          monetate-id="collection-delivery-you-may-also-like"
        >
          <TsCard
            v-for="n in 6"
            :key="n"
            append-class="p-1 m-1 w-48 bg-idle-white flex-none"
            no-border
          >
            <TsRow append-class="justify-center pt-4">
              <NuxtImg
                width="105"
                height="105"
                class="rounded-xl bg-white"
                src="/images/dewaltProductListing.png"
              />
            </TsRow>
            <TsTypography as="p" size="xs" append-class="text-right mr-2"
              >Id: 57726</TsTypography
            >

            <TsColumn>
              <TsTypography append-class="mb-0 text-idle-black"
                ><span class="font-semibold">DeWALT</span> DCH172NT-XJ XR
                SDS-Plus
              </TsTypography>
              <TsRow append-class="m-0 justify-between">
                <TsTypography
                  size="sm"
                  append-class="mb-0 line-through leading-tight mt-1"
                >
                  €259.00
                </TsTypography>
                <TsRatings :modelValue="1" :stars="1" label="4.5" />
              </TsRow>
              <TsRow append-class="m-0">
                <TsTypography
                  variant="h2"
                  weight="bold"
                  append-class="text-idle-black leading-normal mb-0 leading-tight"
                >
                  €199.00
                </TsTypography>
                <TsTypography append-class="mt-1 ml-2" size="xs">
                  $0.60/m
                </TsTypography>
              </TsRow>
              <TsTypography size="xs" append-class="leading-tight">
                Excl. VAT €164.46
              </TsTypography>
              <TsButton
                icon="bx:cart"
                size="sm"
                label="Add to trolley"
                append-class="mt-3"
                outlined
                block
              />
            </TsColumn>
          </TsCard>
        </TsRow>
      </div>
    </div>

    <TsRow append-class="px-0 lg:px-36 p-2" v-if="enableOutOfStock">
      <TsColumn>
        <TsButton
          block
          icon="mdi:bell-outline"
          label="Notify me"
          @click="emit('close')"
          size="sm"
        />
      </TsColumn>
    </TsRow>

    <TsRow append-class="px-0 lg:px-36 p-2" v-else-if="enableNextDayCollection">
      <TsColumn>
        <TsButton
          block
          icon="hugeicons:store-verified-01"
          :label="`Next Day Collection at ${branchStore.lastSavedBranch?.name}`"
          @click="emit('goToTrolley')"
          size="sm"
          :disabled="props.loading"
        />
      </TsColumn>
    </TsRow>

    <TsRow append-class="m-0 justify-between p-2" v-else>
      <TsColumn>
        <TsButton
          block
          label="Continue shopping"
          size="sm"
          outlined
          @click="emit('close')"
        />
      </TsColumn>
      <TsColumn>
        <TsButton
          block
          icon="mdi:trolley-outline"
          label="Go to trolley"
          @click="emit('goToTrolley')"
          size="sm"
        />
      </TsColumn>
    </TsRow>

    <!-- Overlay Loader -->
    <div
      v-if="trolleyItemProcessing"
      class="absolute inset-0 bg-idle-black/15 z-10 rounded-lg"
    >
      <!-- Progress Bar -->
      <div
      class="absolute top-0 left-0 z-50 h-2 transition-all bg-primary"
      :style="{ width: progressBarWidth + '%' }"
    ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";

const branchStore = useBranchStore();
const rootStore = useRootStore();

type Props = {
  loading?: boolean;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  setBranch: any;
  close: any;
  goToTrolley: any;
  clickVariants: any;
  findStock: [quantity: number];
}>();

const trolleyStore = useTrolleyStore();

function handleModalClose() {
  emit("close");
}

// main indicator
const enableNextDayCollection = computed<boolean>(() => {
  return (
    (trolleyStore.previewed_product_v2?.next_business_day_collection &&
      trolleyStore.previewed_product_v2?.outOfStockForCollection &&
      !trolleyStore.previewed_product_v2?.outOfStockForDelivery) ||
    // Previously, we were comparing `trolleyStore.collection_quantity_counter` instead of `lineItem.quantity`
    // synced with trolley now
    (lineItem.quantity >
      trolleyStore.previewed_product_v2?.stockDetails?.collection! &&
      lineItem.quantity <=
        trolleyStore.previewed_product_v2?.stockDetails?.delivery!)
  );
});

const enableOutOfStock = computed<boolean>(() => {
  return (
    (trolleyStore.previewed_product_v2?.outOfStockForCollection &&
      trolleyStore.previewed_product_v2?.outOfStockForDelivery) ||
    (lineItem.quantity >
      trolleyStore.previewed_product_v2?.stockDetails?.collection! &&
      lineItem.quantity >
        trolleyStore.previewed_product_v2?.stockDetails?.delivery!)
  );
});

const availableStockForCollection = computed(() =>
  enableNextDayCollection.value
    ? trolleyStore.previewed_product_v2?.stockDetails?.delivery
    : trolleyStore.previewed_product_v2?.stockDetails?.collection
);

const trolleyItemProcessing = ref(false);

const lineItem = reactiveComputed<any>(() => {
  if (!trolleyStore.trolley_line_items) return { quantity: 1 };
  return (
    Object.values(trolleyStore.trolley_line_items)
      .flatMap((item) => item)
      .find(
        (line) => line.product_code === trolleyStore.previewed_product_v2?.code
      ) ?? { quantity: 1 }
  );
});

// clicking on collection variants
function handleCollectionVariantsClick() {
  trolleyStore.collection_product_variants = [];
  if (!trolleyStore.previewed_product_v2?.variations.length) return;

  trolleyStore.preview_overlay_collection_visible = false;
  trolleyStore.collection_variants_modal_visible = true;
  const stockStore = useStockStore();

  stockStore
    .getStockForProducts(trolleyStore.previewed_product_v2?.variations)
    .then((response) => {
      trolleyStore.collection_product_variants = response.map((product) => ({
        ...product,
        quantity: 0,
        channel: stockStore.isEligibleForNextDayCollection(product)
          ? TrolleyChannel.NextDayCollection
          : TrolleyChannel.Collection,
      }));
    });
}

const updateTrolleyItemQuantity = async () => {
  // return if completely out of stock
  if (enableOutOfStock.value || !lineItem) return;

  trolleyItemProcessing.value = true;

  // update product quantity in trolley
  await trolleyStore.updateQuantity(lineItem.id, lineItem.quantity);

  // dispatch for next day collection when quantity is increased and within bound
  if (enableNextDayCollection.value)
    await trolleyStore.updateChannel(
      lineItem.id,
      TrolleyChannel.NextDayCollection
    );
  // when quantity is reduced send to collection
  else await trolleyStore.updateChannel(lineItem.id, TrolleyChannel.Collection);
  trolleyItemProcessing.value = false;
};

// for loading overlay
watch(trolleyItemProcessing, (isProcessing) => {
  if (isProcessing) {
    startProgress();
  } else {
    resetProgress();
  }
});

const progressBarWidth = ref(0);
let intervalId: NodeJS.Timeout | null = null;

const startProgress = () => {
  progressBarWidth.value = 0;
  intervalId = setInterval(() => {
    if (progressBarWidth.value < 100) {
      progressBarWidth.value += 1;
    } else {
      clearInterval(intervalId!);
    }
  }, 5);
};

const resetProgress = () => {
  if (intervalId) {
    clearInterval(intervalId);
  }
  progressBarWidth.value = 0;
};
</script>
